import type { ComponentType } from "react"
import { useEffect, useState } from "react"

export function withLegalzoomExperiment(Component): ComponentType {
    return (props) => {
        const [flagsLoaded, setFlagsLoaded] = useState(false)

        useEffect(() => {
            if (!window?.posthog) return

            if (flagsLoaded) {
                console.log("flags already loaded, exiting.")
                return
            }

            console.log("setting flags loaded=true")
            setFlagsLoaded(true)
            var variant = window?.posthog?.getFeatureFlag("legalzoom-lead-form")
            // find the element we will hide based on the flag variant
            const elToHide = document.querySelector(
                variant === "control"
                    ? "[aria-label='lead-form']"
                    : "[aria-label='get-started-ctas']"
            )
            // hide the element
            if (elToHide) {
                elToHide.style.display = "none"
            } else {
                console.error("could not find target element for experiment")
            }

            // if ("test" === variant) {
            //     // if needed, update your page for the test variant.
            //     // this is not needed if you update your code elsewhere.
            //     console.log("test")
            //     if (el) {
            //         el.style.display = 'none';
            //     }
            // } else if (variant === "control") {
            //     console.log("control")
            // }
            // Need to make sure we always display the hidden element.

            // show the page again
            var elements = document.querySelectorAll(".ph-hide-element")
            document.body.classList.remove("ph-hide-element")
        }, [flagsLoaded])

        console.log("rendering, flags loaded", flagsLoaded)

        return <Component {...props} />
    }
}

export function withSurveyExperiment(Component): ComponentType {
    return (props) => {
        const [flagsLoaded, setFlagsLoaded] = useState(false)

        useEffect(() => {
            if (!window?.posthog) return

            if (flagsLoaded) {
                console.log("flags already loaded, exiting.")
                return
            }

            console.log("setting flags loaded=true")
            setFlagsLoaded(true)
            var variant = window?.posthog?.getFeatureFlag("survey-experiment")
            console.log("variant", variant)

            // find the element we will hide based on the flag variant
            const elsToHide = document.querySelectorAll(
                `[aria-label='variant-${variant}']`
            )
            // hide the element
            if (elsToHide.length > 0) {
                console.log(`hiding ${elsToHide.length} elements`)
                elsToHide.forEach((elToHide) => {
                    elToHide.style.display = "none"
                })
            } else {
                console.error("could not find target element for experiment")
            }

            // show the page again
            var elements = document.querySelectorAll(".ph-hide-element")
            document.body.classList.remove("ph-hide-element")
        }, [flagsLoaded])

        console.log("rendering, flags loaded", flagsLoaded)

        return <Component {...props} />
    }
}
